import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../Assets/NewtupleLogo.png'; // Adjust the path as needed

const CompanyLogo = () => {
  return (
    <Box
      component={Link}
      to="/"
      sx={{
        position: 'fixed',
        top: 20,
        left: 20,
        zIndex: 1200,
        '&:hover': {
          opacity: 0.8,
        },
      }}
    >
      <img src={logo} alt="Newtuple Logo" style={{ height: '50px' }} />
    </Box>
  );
};

export default CompanyLogo;
