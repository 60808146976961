import React, { useState, useEffect } from "react";
import Vapi from "@vapi-ai/web";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Autocomplete,
  InputAdornment,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ProfileMenu from "../Components/ProfileMenu";
import CompanyLogo from "../Components/CompanyLogo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/botPage.css";
import { countries } from "../utils/Countries";
import { keyframes } from '@mui/system';

// Import fonts
import '@fontsource/poppins/700.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/700.css';

// Create a custom theme with Poppins and Nunito fonts
const theme = createTheme({
  typography: {
    fontFamily: 'Nunito, Arial, sans-serif',
    h1: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 700,
    },
  },
});

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const ASSISTANT_ID = process.env.REACT_APP_SALES_ASSISTANT_ID;
const PHONE_NUMBER_ID = process.env.REACT_APP_PHONE_NUMBER_ID;
const API_KEY = process.env.REACT_APP_PRIVATE_KEY;

const vapi = new Vapi(PUBLIC_KEY);

// Country data (as provided in your example)

const OutboundCall = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState("");
  const [isCallInitiated, setIsCallInitiated] = useState(false);

  const startCall = async () => {
    if (!selectedCountry || !phoneNumber) {
      setError("Please select a country and enter a valid phone number.");
      return;
    }

    setConnecting(true);
    setError("");
    setIsCallInitiated(false);

    const fullPhoneNumber = `+${selectedCountry.phone}${phoneNumber}`;

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assistantId: ASSISTANT_ID,
        phoneNumberId: PHONE_NUMBER_ID,
        customer: {
          number: fullPhoneNumber,
        },
      }),
    };

    try {
      const response = await fetch("https://api.vapi.ai/call/phone", options);
      const data = await response.json();

      if (response.ok) {
        console.log("Call initiated successfully:", data);
        toast.info("Initiating call...");
        setIsCallInitiated(true);
      } else {
        throw new Error(data.message || "Failed to start the call");
      }
    } catch (error) {
      console.error("Error starting outbound call:", error);
      setError(error.message || "Failed to start the call. Please try again.");
      toast.error("Failed to start call. Please try again.");
    } finally {
      setConnecting(false);
    }
  };

  // Define keyframes for animations
  const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  `;

  const pulse = keyframes`
    0% { box-shadow: 0 0 0 0 rgba(107, 91, 255, 0.7); }
    70% { box-shadow: 0 0 0 10px rgba(107, 91, 255, 0); }
    100% { box-shadow: 0 0 0 0 rgba(107, 91, 255, 0); }
  `;

  return (
    <ThemeProvider theme={theme}>
      <Box id="voice-bot-main-container"
        sx={{
          animation: `${fadeIn} 0.5s ease-out`,
        }}
      >
        <CompanyLogo />
        <ProfileMenu />
        <Box id="voice-bot-header">
          <Typography
            variant="h4"
            sx={{

              color: "#ffffff",
            fontWeight: 700,
            background: "linear-gradient(45deg, #1A2552, #3221f3)",
            backgroundClip: "text",
            textFillColor: "transparent",
            mb: 3,
            }}
          >
            Newtuple AI Outbound Call
          </Typography>
        </Box>
        <Box
          id="voice-bot-container"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            padding: { xs: 2, md: 4 },
            margin: "0 auto",
            maxWidth: "1200px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderRadius: "10px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            animation: `${fadeIn} 0.5s ease-out 0.2s both`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "500px",
              backgroundColor: "#1d1d1d", // Dark background
              borderRadius: "8px",
              overflow: "hidden",
              border: "2px solid #79c2ee",
              transition: "box-shadow 0.3s ease",
              '&:hover': {
                boxShadow: '0 0 0 2px #79c2ee',
              },
            }}
          >
            <TextField
              variant="standard"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter your number"
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "#fff", // Text color
                  padding: "10px",
                  // backgroundColor: "#1d1d1d", // Dark background
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) => `+${option.phone}`}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                          />
                          +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "120px",
                            "& .MuiInputBase-input": {
                              padding: "10px",
                              color: "#fff", // Country code text color
                            },
                            "& .MuiInputBase-root": {
                              border: "none",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove the outline border
                            },

                            "& .css-20bmp1-MuiSvgIcon-root": {
                              // display: "none", // Hide the dropdown arrow
                              color: "gray",
                            },
                          }}
                          placeholder="Country"
                        />
                      )}
                      value={selectedCountry}
                      onChange={(event, newValue) => {
                        setSelectedCountry(newValue);
                      }}
                      disabled={connecting}
                      sx={{
                        backgroundColor: "#1d1d1d",
                        "& .MuiAutocomplete-inputRoot": {
                          border: "none", // Remove any border on the root element
                        },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                flex: 1,
                "& input::placeholder": {
                  color: "#fffff", // Lighter placeholder color
                },
                color: "#fff", // Text color
              }}
            />
            <Button
              variant="contained"
              onClick={startCall}
              disabled={connecting}
              sx={{
                height: "56px",
                backgroundColor: "#0047AB", // Purple button color
                color: "#fff", // White text
                borderRadius: "0", // No rounded corners to match your design
                "&:hover": {
                  backgroundColor: "#574ac7", // Darker purple on hover
                },
                padding: "10px 20px",
                animation: connecting && !isCallInitiated ? `${pulse} 1.5s infinite` : 'none',
              }}
            >
              {connecting && !isCallInitiated ? <CircularProgress size={24} /> : "CALL ME"}
            </Button>
          </Box>
          {/* {connected && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CallEndIcon />}
              onClick={endCall}
              sx={{ mt: 2 }}
            >
              End Call
            </Button>
          )} */}
        </Box>
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </ThemeProvider>
  );
};

export default OutboundCall;
