import React from 'react';
import { keyframes } from '@mui/system';
import { Box } from '@mui/material';

const pulse = keyframes`
  0%, 100% { transform: scaleY(0.3); }
  50% { transform: scaleY(1); }
`;

const VoiceAnimation = ({ isActive }) => {
  const barCount = 18;
  const baseColor = 'black';
  const activeColor = '#1a2552';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        width: '160px',
        gap: '4px',
      }}
    >
      {[...Array(barCount)].map((_, i) => (
        <Box
          key={i}
          sx={{
            width: `${100 / barCount}%`,
            height: '80%',
            backgroundColor: isActive ? activeColor : baseColor,
            borderRadius: '4px',
            transition: 'background-color 0.3s ease',
            animation: isActive
              ? `${pulse} ${0.9 + i * 0.1}s ease-in-out infinite`
              : 'none',
            opacity: isActive ? 1 : 0.5,
            transform: 'scaleY(0.3)',
            transformOrigin: 'bottom',
          }}
        />
      ))}
    </Box>
  );
};

export default VoiceAnimation;
