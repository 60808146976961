import React, { useEffect, useState } from "react";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "../utils";
import {
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Button,
  Typography,
  Container,
  styled,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LiveTranscript from "../Components/LiveTranscript";
import "../styles/botPage.css";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";
import MicIcon from "@mui/icons-material/Mic";
import VoiceAnimation from "../Components/VoiceAnimation";
import { keyframes } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import { useData } from "../Contexts/DataContext";
import SuggestionCard from "../Components/SuggestionCard";
import ProfileMenu from "../Components/ProfileMenu";
import MicOffIcon from '@mui/icons-material/MicOff';
import CompanyLogo from "../Components/CompanyLogo"; // Add this import
import 'react-toastify/dist/ReactToastify.css';

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
const SQUAD_ID = process.env.REACT_APP_SQUAD_ID;
const SALES_ASSISTANT_ID = process.env.REACT_APP_SALES_ASSISTANT_ID;

const vapi = new Vapi(PUBLIC_KEY);

const BotPage = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] =
    useState(false);
  const [conversation, setConversation] = useState([]);
  const [isMuted, setIsMuted] = useState(vapi.isMuted());
  const [hasTranscript, setHasTranscript] = useState(false);
  const [assistantID, setAssistantID] = useState();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const { setSpinner, spinner, onboardingData } = useData();
console.log(selectedAssistant);
  const assistant_variables = onboardingData?.assistant_variables?.items[0];
  const REACT_APP_INTERVIEW_ASSISTANT_ID =
    process.env.REACT_APP_INTERVIEW_ASSISTANT_ID;
  const REACT_APP_COACH_ASSISTANT_ID = process.env.REACT_APP_COACH_ASSISTANT_ID;

  const ContactUsSuggestions = [
    "Can you tell me more about your product offerings?",
    "What AI use cases have you worked on?",
    "Have you worked with any technical support automations?",
    "How can I get in touch with a sales representative?",
    "Do you offer customer support for your products?",
  ];

  const blinkAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  `;

  const assistants = [
    { id: SALES_ASSISTANT_ID, name: "Sales Assistant" },
    // { id: ASSISTANT_ID, name: "Technical Support Assistant" },
  ];

  useEffect(() => {
    const handleMuteChange = () => setIsMuted(vapi.isMuted());

    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => setAssistantIsSpeaking(true));
    vapi.on("speech-end", () => setAssistantIsSpeaking(false));
    vapi.on("volume-level", (level) => setVolumeLevel(level));

    vapi.on("error", (error) => {
      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    vapi.on("message", (message) => {
      if (message.type === "conversation-update" && message.conversation) {
        if (message.conversation.length > 0) {
          message.conversation = message.conversation.filter(
            (msg) => msg.role !== "system"
          );
        }
        setConversation(message.conversation);
        setHasTranscript(message.conversation.length > 0);
      }
    });

    vapi.on("mute-status-change", handleMuteChange);

    return () => {
      vapi.off("mute-status-change", handleMuteChange);
    };
  }, []);

  const handleAssistantChange = (event) => {
    setSelectedAssistant(event.target.value);
  };

  const startCallInline = () => {
    setConnecting(true);
    if (!selectedAssistant) {
      // Use toast.warn instead of toast.warning
      toast.warn("Please select an assistant first.");
      return;
    }

    const assistantOverrides = {
      // Add any necessary overrides here
    };

    vapi.start(selectedAssistant, assistantOverrides).then((call) => {
      // You can add any additional logic here
    }).catch((error) => {
      console.error("Error starting call:", error);
      setConnecting(false);
      toast.error("Failed to start call. Please try again.");
    });
  };

  const endCall = () => {
    vapi.stop();
    setConnected(false);
    setConnecting(false);
  };

  const toggleMute = () => {
    vapi.setMuted(!isMuted);
    setIsMuted(!isMuted);
  };

  const handleSuggestionClick = (suggestion) => {
    if (connected) {
      toast.info("Sending suggestion to assistant...");
      vapi.send({
        type: "add-message",
      message: {
        role: "user",
        content: suggestion,
        },
      });
    }
  };

  const formatTranscript = (conversation) => {
    return conversation
      .map(
        (message) =>
          `${message.role.charAt(0).toUpperCase() + message.role.slice(1)}: ${
            message.content
          }`
      )
      .join("\n");
  };

  const downloadTranscript = () => {
    const transcriptData = formatTranscript(conversation);
    const doc = new jsPDF();

    // Page setup
    const margin = 10; // Margin from the edge
    const padding = 15; // Padding inside the border
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Header
    doc.setFontSize(18);
    doc.setFont("Arial", "bold");
    doc.text("Transcript", margin + padding, margin + padding + 10); // Header text

    // Border
    doc.rect(
      margin,
      margin,
      pageWidth - 2 * margin,
      pageHeight - 2 * margin,
      "S"
    );

    // Add the transcript data
    doc.setFontSize(10);
    doc.setFont("Arial", "normal");
    const contentWidth = pageWidth - 2 * margin - 2 * padding;
    const contentHeight = pageHeight - 2 * margin - 2 * padding;
    const lines = doc.splitTextToSize(transcriptData, contentWidth);

    let y = margin + padding + 20; // Start after header and padding
    lines.forEach((line) => {
      if (y > pageHeight - margin - padding - 10) {
        // Check if new page is needed
        doc.addPage();
        y = margin + padding + 10;
        doc.text("Transcript", margin + padding, margin + padding + 10); // Re-add header on new page
        doc.rect(
          margin,
          margin,
          pageWidth - 2 * margin,
          pageHeight - 2 * margin,
          "S"
        ); // Re-add border
      }
      doc.text(line, margin + padding, y);
      y += 10;
    });

    // Add page numbers
    doc.setFontSize(8);
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${totalPages}`,
        pageWidth - margin - padding - 20,
        pageHeight - margin - padding + 10
      );
    }

    // Save the PDF
    doc.save("transcript.pdf");
  };

  const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  `;

  return (
    <>
      <CompanyLogo />
      <ProfileMenu/>
      <Box id="voice-bot-main-container"
      sx={{
        animation: !spinner && `${fadeIn} 0.5s ease-out`,
      }}
    >
        <Box id="voice-bot-header" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{
            color: "#ffffff",
            fontWeight: 700,
            // background: "linear-gradient(45deg, #00ffcc, #ffffffb5)",
            background: "linear-gradient(45deg, #1A2552, #3221f3)",

            backgroundClip: "text",
            textFillColor: "transparent",
            mb: 3,
          }}>
            Newtuple AI Assistant
          </Typography>
          <FormControl sx={{ mb: 3, width: "300px" }}>
            <Select
              labelId="assistant-select-label"
              id="assistant-select"
              value={selectedAssistant}
              onChange={handleAssistantChange}
              displayEmpty
              variant="outlined"
              sx={{
                color: "#1A2552",
                backgroundColor: "#FFFFFF",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1A2552",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2A3562",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A90E2",
                },
                "& .MuiSvgIcon-root": {
                  color: "#1A2552",
                },
                height: "40px",
                width: "300px",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                    '& .MuiMenuItem-root': {
                      color: '#1A2552',
                      '&:hover': {
                        bgcolor: 'rgba(26, 37, 82, 0.1)',
                      },
                      '&.Mui-selected': {
                        bgcolor: 'rgba(26, 37, 82, 0.2)',
                        '&:hover': {
                          bgcolor: 'rgba(26, 37, 82, 0.3)',
                        },
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                <em style={{ color: '#4A5568' }}>Select Assistant</em>
              </MenuItem>
              {assistants.map((assistant) => (
                <MenuItem key={assistant.id} value={assistant.id}>
                  {assistant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          id="voice-bot-container"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 3,
            padding: { xs: 2, md: 4 },
            margin: "0 auto",
            maxWidth: "1200px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderRadius: "10px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
            },
          animation: !spinner && `${fadeIn} 0.5s ease-out 0.2s both`,
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", md: "300px" },
              margin: "0 auto",
            }}
          >
            <SuggestionCard
              onSuggestionClick={handleSuggestionClick}
              suggestions={ContactUsSuggestions}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              maxWidth: { xs: "100%", md: "300px" },
              marginTop: "50px",
              position: "relative",
              minHeight: "450px",
            }}
          >
            {/* Call button */}
            <Box
              className="call-button"
              onClick={connected ? null : startCallInline}
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: connected
                  ? "rgba(62, 240, 124, 0.2)"
                  : "rgba(0, 176, 255, 0.2)",
                cursor: connected ? "default" : "pointer",
                transition: "all 0.3s ease",
                margin: "0 auto",
                "&:hover": {
                  backgroundColor: connected
                    ? "rgba(62, 240, 124, 0.2)"
                    : "rgba(0, 176, 255, 0.3)",
                },
                "& .MicIcon": {
                  animation: connecting
                    ? `${blinkAnimation} 1s infinite`
                    : "none",
                },
              }}
            >
              {connected ? (
                <VoiceAnimation isActive={true} />
              ) : (
                <MicIcon
                  className="MicIcon"
                  sx={{
                    fontSize: 80,
                    color: connecting ? "#FF4081" : "#1A2552",
                  }}
                />
              )}
            </Box>

            {connected && (
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={toggleMute}
                  startIcon={isMuted ? <MicOffIcon /> : <MicIcon />}
                  sx={{
                    mb: 2,
                    width: "200px",
                    backgroundColor: "transparent",
                    color: "#1A2552",
                    borderColor: "#1A2552",
                    "&:hover": {
                      backgroundColor: "rgba(26, 37, 82, 0.1)",
                      borderColor: "#1A2552",
                    },
                  }}
                >
                  {isMuted ? "Unmute Call" : "Mute Call"}
                </Button>
                <Button
                  variant="contained"
                  onClick={endCall}
                  sx={{
                    mb: 2,
                    width: "200px",
                    backgroundColor: "#1A2552",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#2A3562",
                    },
                  }}
                >
                  End Call
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", md: "400px" },
            }}
          >
            {connected && (
              <LiveTranscript
                conversation={conversation}
                downloadTranscript={downloadTranscript}
                hasTranscript={hasTranscript}
                isConnected={connected}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={showPublicKeyInvalidMessage}
        autoHideDuration={3000}
        onClose={() => setShowPublicKeyInvalidMessage(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Is your Vapi Public Key missing? (recheck your code)
        </Alert>
      </Snackbar>
      {spinner && <LoadingSpinner />}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default BotPage;
