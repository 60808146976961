import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme } from "./styles/theme";
import { DataProvider } from "./Contexts/DataContext";
import LandingPage from "./Pages/LandingPage";
import BotPage from "./Pages/BotPage";
import SignInPage from "./Pages/SignInPage";
import SignUpPage from "./Pages/SignUpPage";
import ProfileMenu from "./Components/ProfileMenu";
import VoiceCall from "./Pages/VoiceCall";

const PrivateRoute = ({ children }) => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  if (user && token) {
    return children;
  }
  return <Navigate to="/" replace />;
};

const PublicRoute = ({ children }) => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  if (user && token) {
    return <Navigate to="/landing" replace />;
  }
  return children;
};

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <DataProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <SignInPage />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <SignUpPage />
                </PublicRoute>
              }
            />
            <Route
              path="/landing"
              element={
                <PrivateRoute>
                  <LandingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/bot"
              element={
                <PrivateRoute>
                  <BotPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/voice-call"
              element={
                <PrivateRoute>
                  <VoiceCall />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </DataProvider>
    </ThemeProvider>
  );
};

export default App;
